import React, { PureComponent, useState, useCallback, useEffect } from "react";
import Helmet from "react-helmet"
import IndexSPA from "../../components/IndexSPA"

const Index = (props) => {
   return (
      <>
        <Helmet
          title="展覽論壇 - 看見困難在哪裡，大家一起翻越那座山 / 藝術與空間的碰撞 / HOME TO ALL 我們也可以這樣生活 / 臺北社宅藝術計劃線上展覽"
          meta={[
            {
              name: `description`,
              content: "很開心先跟社會各藝文界的朋友，以及社區的朋友討論什麼樣的公共藝術，才符合現今台北文化情境的需求？我們不是要找一個大論述解決一切，而是在這段期間盡量聆聽，讓更多有天份的人加入討論。目前40個社宅基地，有5個在帝門藝術基金會的擘劃之下，公共藝術已經逐步完成。但我們還是得思考，如何面對剩下的35處基地？",
            },
            {
              property: `og:title`,
              content: "展覽論壇 - 看見困難在哪裡，大家一起翻越那座山 / 藝術與空間的碰撞 / HOME TO ALL 我們也可以這樣生活 / 臺北社宅藝術計劃線上展覽",
            },
            {
              property: `og:description`,
              content: "很開心先跟社會各藝文界的朋友，以及社區的朋友討論什麼樣的公共藝術，才符合現今台北文化情境的需求？我們不是要找一個大論述解決一切，而是在這段期間盡量聆聽，讓更多有天份的人加入討論。目前40個社宅基地，有5個在帝門藝術基金會的擘劃之下，公共藝術已經逐步完成。但我們還是得思考，如何面對剩下的35處基地？",
            },
            {
              property: `og:image`,
              content: 'https://online.home-to-all.com' + require('../../images/422socialimage.jpg')
            }
          ]}
        >
        </Helmet>
         <IndexSPA />
      </>
   )
 };
 
 
 export default Index;